<template>
  <div class="banner-section" data-aos="fade-up">
    <div class=" __banner-content">
      <div class="content-title">
        <h1 class="banner-title">{{ titulo }}</h1>
        <h2>{{ subtitulo }}</h2>
      </div>
      <div class="description-container">
        <p>{{ descripcion }}</p>
      </div>
<!--      <div class="pt-5" @click="goToRegister">-->
<!--        <button class="banner-button mt-5">{{ actionData.text }} {{actionData.text2}} {{actionData.markedText}}</button>-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>
import store from '../../store'

export default {
  name: "Banner",
  data() {
    return {
      actionData: {},
      titulo: "Wetforest",
      subtitulo: "Ventures",
      descripcion:
        "Plataforma de negocios para impactar el futuro de emprendimientos y empresas en tiempos de grandes cambios.",
    };
  },
  methods: {
    goToRegister() {
      this.$router.push('/register')
    },
    fetchData() {
      if (store.state.sections.floatSection) {
        this.actionData = { ...store.state.sections.floatSection.components };
      }
      if (store.state.sections.banner) {
        this.titulo = store.state.sections.banner.components.title;
        this.subtitulo = store.state.sections.banner.components.subtitle;
        this.descripcion = store.state.sections.banner.components.text;
      }
    }
  },

  watch: {
    "$store.state.sections": function () {
      this.fetchData();
    },
  },

  mounted() {
    this.fetchData();
  },

  props: {
    Idioma: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.banner-section {
  background-image: url("../../assets/banner.webp");
  background-size: cover;
  min-height: 100vh;
  display: grid;
  place-items: center;
  position: relative;

  .banner-button {
    border-radius: 36px;
    font-weight: bold;
    color: #044819;
    padding: 1rem 1.5rem;
    border-color: transparent;
    background-color: #FEF080;

    &:hover {
      transition: background-color 200ms ease;
      background-color: #fffb64;
    }
  }

  h1, h2, p {
    color: white;
  }

  h1 {
    font-family: 'OpenSans-SemiBold' !important;
    font-size: 3rem !important;
  }

  h2 {
    font-family: 'OpenSans-Regular' !important;
    margin-left: 5rem;
    font-size: 2rem !important;
    font-weight: 400 !important;
  }

  p {
    font-size: 1.2rem !important;
  }


  .__banner-content {
    text-align: center;
  }

  .description-container {
    width: 100%;
    display: flex;
    justify-content: center;

    p {
      width: 90%;
    }
  }
}

@media (min-width: 768px) {
  .banner-section {
    min-height: 80vh;
  }

  .description-container {
    p {
      width: 60% !important;
    }
  }
}

@media (min-width: 1200px) {
  .banner-section {
    min-height: 100vh;

    .__banner-content {
      max-width: 50%;
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    .content-title {
      max-width: fit-content;
      text-align: end;
    }

    h1 {
      font-size: 10rem !important;
      line-height: 6rem;
    }

    h2 {
      font-size: 6rem !important;
      font-weight: 400 !important;
    }

    p {
      font-size: 1.8rem !important;
    }

    .description-container {
      p {
        width: 100% !important;
      }
    }
  }
}
</style>
